import {
    IProductInventory,
    IProductPrice,
} from '../../../engine/PricesAndInventoriesCache';
import { useEffect, useMemo, useState } from 'react';

import { IStoreContextInternalAPI } from '../StoreContext';
import { useStore } from '../useStore';

export function useProductPrice(sku: string, options?: { maxAge?: number }) {
    const {
        pricesAndInventoriesCache,
        currentStore,
    } = useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [price, setPrice] = useState<IProductPrice | undefined>(() =>
        productResource.getPriceImmediate()
    );

    useEffect(() => {
        setPrice(productResource.getPriceImmediate());
        productResource.subscribePrice(setPrice, { maxAge: options?.maxAge });

        return () => {
            productResource.unsubscribePrice(setPrice);
        };
    }, [productResource, options, setPrice]);

    return [price] as const;
}

// FIXME: This hook is only used for the filter-by-price usecase. That specific component has no SSR
//        requirement. Using hooks for this purpose is difficult, innefficient, and not totaly reliable.
//        That component should be updated to obtain prices another way (using a query API directly
//        to the cache).
export function useProductsPrices(
    skus: string[],
    options?: { maxAge?: number }
) {
    const {
        pricesAndInventoriesCache,
        currentStore,
    } = useStore() as IStoreContextInternalAPI;
    const productsResources = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');

        return skus.map((sku) =>
            pricesAndInventoriesCache.getProductResource(sku)
        );
    }, [pricesAndInventoriesCache, currentStore, skus]);

    const [prices, setPrices] = useState<(IProductPrice | undefined)[]>(() =>
        productsResources.map((productResource) =>
            productResource.getPriceImmediate()
        )
    );

    return [prices] as const;
}

export function useProductInventory(
    sku: string,
    options?: { maxAge?: number }
) {
    const {
        pricesAndInventoriesCache,
        currentStore,
    } = useStore() as IStoreContextInternalAPI;
    const productResource = useMemo(() => {
        if (!currentStore) throw new Error('Current Store is not yet defined');
       
        return pricesAndInventoriesCache && pricesAndInventoriesCache.getProductResource(sku);
    }, [pricesAndInventoriesCache, currentStore, sku]);

    const [inventory, setInventory] = useState<IProductInventory | undefined>(
        () => productResource && productResource.getInventoryImmediate()
    );
    
    useEffect(() => {        
        setInventory(productResource.getInventoryImmediate());
        productResource.subscribeInventory(setInventory, {
            maxAge: options?.maxAge,
        });
        return () => {
            productResource.unsubscribeInventory(setInventory);
        };
    }, [productResource, options, setInventory]);

    return [inventory] as const;
}
